import React, {Component} from 'react';
import AUX from '../../../hoc/Aux_';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import * as actionTypes from '../../../store/action';
import LoginButton from '../../../components/Auth/LoginButton';

class Pages_login_2 extends Component {

    componentDidMount() {
        if (this.props.loginpage === false) {
            this.props.UpdateLogin();
        }
        window.onpopstate = (e) => {
            this.props.UpdateLoginAgain();
        };
    }

    render() {
        return (
          <AUX>
              <div className="accountbg"
                   style={{background: 'url(\'assets/images/bg-hero.jpg\')', backgroundSize: 'cover'}}></div>
              <div className="wrapper-page account-page-full">
                  <div className="card">
                      <div className="card-body">

                          <h3 className="text-center m-0">
                              <Link to="/" onClick={() => this.props.UpdateLoginAgain()}
                                    className="logo logo-admin"><img src="assets/images/logo-text@2x.png" height="60"
                                                                     alt="logo"/></Link>
                          </h3>

                          <div className="p-3">
                              <h4 className="font-18 m-b-5 text-center">Welcome Back!</h4>
                              <div style={
                                  {
                                      display: 'flex',
                                      marginTop: '20px',
                                      justifyContent: 'center'
                                  }
                              }>
                                  <LoginButton/>
                              </div>

                          </div>

                      </div>
                  </div>

                  <div className="m-t-40 text-center">
                      <p className="">Don't have an account ? <Link to="pages_register_2"
                                                                    className="font-500 font-14 font-secondary"> Signup
                          Now </Link></p>
                      <p className="">©{new Date().getFullYear()} Smart Impact LLC</p>
                  </div>
              </div>
          </AUX>
        );
    }
}

const mapStatetoProps = state => {
    return {
        loginpage: state.ui_red.loginpage
    };
};

const mapDispatchtoProps = dispatch => {
    return {
        UpdateLogin: () => dispatch({type: actionTypes.LOGINPAGE, value: true}),
        UpdateLoginAgain: () => dispatch({type: actionTypes.LOGINPAGE, value: false})
    };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(Pages_login_2);
