import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {createStore, combineReducers} from 'redux';
import '../node_modules/fullcalendar/dist/fullcalendar.min.js';
import UIreducer from './store/reducers/reducer';
import {Provider} from 'react-redux';
import {Auth0Provider} from '@auth0/auth0-react';

const rootReducer = combineReducers({
    ui_red: UIreducer
});
const store = createStore(rootReducer);
const app = (
  <Auth0Provider
    domain='dev-fgz09bzr.auth0.com'
    redirectUri={window.location.origin}
    clientId='6Gaicz0UAIG5NVdcc01s4PaD1fucDOAn'>
      <Provider store={store}>
          <BrowserRouter>
              <App/>
          </BrowserRouter>
      </Provider>
  </Auth0Provider>
);

ReactDOM.render(app, document.getElementById('root'));
serviceWorker.unregister();
