import React , {Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { Link } from 'react-router-dom';

class Pages_blank extends Component{
 
render(){
    return(
           <AUX>
		   
		    <div class="page-content-wrapper">
            <div class="container-fluid">

            </div> 
            </div>
            
           </AUX>
        );
    }
}

export default Pages_blank;   