import React, {Component} from 'react';
import Layout from './components/Layout/Layout';
import {Route, Switch} from 'react-router-dom';
import mainbuilder from './containers/mainbuilder/mainbuilder';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import AUX from './hoc/Aux_';
import {useAuth0} from '@auth0/auth0-react';
import Pages_login_2 from './containers/MainContent/Pages/Pages_login_2';
import {HEADER} from './store/action';
import {Footer} from 'mdbreact';

const App = (props) => {
    let layout = null;
    const {user, isAuthenticated} = useAuth0();
    layout = (
      <Layout header={isAuthenticated} sidebar={isAuthenticated} footer={isAuthenticated}
              loginpage={!isAuthenticated}>
          <Switch>
              {
                  isAuthenticated
                    ?
                    <Route path="/" component={mainbuilder}/>
                    : <Route path="/" component={Pages_login_2}/>

              }

          </Switch>
      </Layout>);
    return (
      <AUX>
          {layout}
      </AUX>
    );

};

const mapStatetoProps = state => {
    return {
        header: state.ui_red.header,
        sidebar: state.ui_red.sidebar,
        footer: state.ui_red.footer,
        loginpage: state.ui_red.loginpage
    };
};

export default withRouter(connect(mapStatetoProps)(App));
