import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {Link} from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import $ from 'jquery';

class sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Tab: 'index', SubTab: '', MoreTab: '', dashboard_menu: false, email_menu: false, ui_menu: false,
            form_menu: false, chart_menu: false, table_menu: false, icon_menu: false, map_menu: false,
            auth_menu: false, extra_menu: false, eco_menu: false, emt_menu: false
        };
    }

    setActiveTab = (tab, subtab, moretab, toggleTab, e) => {
        this.setState({Tab: tab, SubTab: subtab, MoreTab: moretab});
    };

    componentDidMount() {
        var now_route = '';
        var pageUrl = window.location.pathname.split(/[?#]/)[0];
        now_route = pageUrl.substr(1).replace(/_/g, ' ');
        $('#now_routing').empty();
        if (now_route == '') { now_route = 'Dashboard1'; } else { }
        $('#now_routing').append(now_route);

        $('.toggle-search').on('click', function () {
            var targetId = $(this).data('target');
            var $searchBar;
            if (targetId) {
                $searchBar = $(targetId);
                $searchBar.toggleClass('open');
            }
        });

        $('.button-menu-mobile').on('click', function (event) {
            event.preventDefault();
            $('body').toggleClass('enlarged');
        });

        $('li.has_sub li').on('click', function (event) {
            $('body').toggleClass('enlarged');
        });
    }

    componentDidUpdate() {
        var now_route = '';
        var pageUrl = window.location.pathname.split(/[?#]/)[0];
        now_route = pageUrl.substr(1).replace('_', ' ');
        $('#now_routing').empty();
        if (now_route == '') { now_route = 'Dashboard1'; } else { }
        $('#now_routing').append(now_route);
    }

    render() {
        return (
          <div className="left side-menu">

              <div className="topbar-left">
                  <div className="">
                      <Link to="/" className="logo"><img src="assets/images/logo-text-w.png" height="60"
                                                         alt="logo"/></Link>
                  </div>
              </div>

              <div className="sidebar-inner slimscrollleft">
                  <PerfectScrollbar>
                      <div id="sidebar-menu">
                          <ul>
                              <li className="menu-title">Main</li>

                              <li>
                                  <Link to="/" onClick={() => {
                                      this.setState({
                                          dashboard_menu: !this.state.dashboard_menu,
                                          email_menu: false,
                                          ui_menu: false,
                                          form_menu: false,
                                          chart_menu: false,
                                          table_menu: false,
                                          icon_menu: false,
                                          map_menu: false,
                                          auth_menu: false,
                                          extra_menu: false,
                                          eco_menu: false,
                                          emt_menu: false
                                      });
                                      this.setActiveTab.bind(this, 'index', '', '');
                                  }
                                  }
                                        className={this.state.Tab === 'index' ? 'waves-effect active-menu' : 'waves-effect'}>
                                      <i className="mdi mdi-view-dashboard"></i> <span> Dashboard <span
                                    className="badge badge-pill badge-primary pull-right">20+</span></span> </Link>
                                  {/*<Link to="#" onClick={()=> this.setState({ dashboard_menu: !this.state.dashboard_menu, email_menu : false, ui_menu: false,form_menu: false,chart_menu: false,table_menu: false,icon_menu: false,map_menu: false,auth_menu: false,extra_menu: false,eco_menu: false,emt_menu: false }) }  className={this.state.Tab === 'index' ? 'waves-effect active-menu':'waves-effect'}  ><i className="mdi mdi-view-dashboard"></i> <span> Dashboard <span className="badge badge-pill badge-primary pull-right">20+</span></span> </Link>
                            <ul className="list-unstyled" style={{display: this.state.dashboard_menu ? 'block' : 'none' }}>
                                    <li><Link className={this.state.SubTab === 'dashboard1' ? 'active-menu':''} onClick={this.setActiveTab.bind(this, 'index','dashboard1','')}  to="/">Dashboard One</Link></li>
                                    <li><Link className={this.state.SubTab === 'dashboard2' ? 'active-menu' : ''} onClick={this.setActiveTab.bind(this, 'index','dashboard2','')}   to="dashboard2">Dashboard Two</Link></li>
                                </ul>*/}
                              </li>
                              <li>
                                  <Link to="widgets"
                                        className={this.state.Tab === 'widget' ? 'waves-effect active-menu' : 'waves-effect'}
                                        onClick={this.setActiveTab.bind(this, 'widget', '', '')}><i
                                    className="mdi mdi-cube-outline"></i><span> Widgets </span></Link>
                              </li>

                              <li className="menu-title">Help & Support</li>

                              <li>
                                  <Link to="pages_faq"
                                        className={this.state.Tab === 'faq' ? 'waves-effect active-menu' : 'waves-effect'}
                                        onClick={this.setActiveTab.bind(this, 'faq', '', '')}><i
                                    className="mdi mdi-help-circle"></i><span> FAQ </span></Link>
                              </li>

                              <li>
                                  <Link to="pages_contact"
                                        className={this.state.Tab === 'contact' ? 'waves-effect active-menu' : 'waves-effect'}
                                        onClick={this.setActiveTab.bind(this, 'contact', '', '')}><i
                                    className="mdi mdi-headset"></i><span> Contact <span
                                    className="badge badge-pill badge-warning pull-right">3</span> </span></Link>
                              </li>

                              <li>
                                  <Link to="#" className="waves-effect"><i
                                    className="mdi mdi-file-document-box"></i><span> Documentation </span></Link>
                              </li>
                          </ul>
                      </div>

                      <div className="clearfix"></div>
                  </PerfectScrollbar>
              </div>

          </div>
        );
    }
}

export default withRouter(sidebar);